<template>
  <transition name="fade" mode="out-in">
    <div v-if="isLoading" :class="classes">
      <DpSpinner />
    </div>
  </transition>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpSpinner: () => import('@/components/dripPod/core/Spinner.vue')
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    fluid: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const classes = computed(() => [
      'dp-page-loading',
      props.fluid ? 'dp-page-loading--fluid' : false
    ]);
    return {
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-page-loading {
  z-index: 10;
  position: absolute;
  top: 48px;
  left: 0;
  display: grid;
  padding: 48px 16px;
  place-items: center;
  background-color: variables.$dpWhite01;
  width: 100%;
  height: calc(100vh - 48px);

  &--fluid {
    top: 0;
    height: 100vh;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
